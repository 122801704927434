<template>
  <v-dialog v-model="dialog" max-width="800px" @click:outside="onClose">
    <v-card color="grey lighten-2" tile>
      <v-card-title>
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title>Link to Quote</v-toolbar-title>
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                @click.stop="onClose"
                v-on="on"
                data-test="close-button"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close this window</span>
          </v-tooltip>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12">
              <v-row dense class="mt-2">
                <v-col cols="12" md="8">
                  <v-text-field
                    dense
                    label="Quote's Project Name"
                    name="projectname"
                    type="text"
                    autocomplete="off"
                    autofocus
                    clearable
                    hide-details
                    v-model="project_name"
                    @click:clear="quote_list.splice(0)"
                    @keypress.enter.stop="onSearch"
                    data-test="project-name"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn
                    block
                    :disabled="!project_name"
                    color="secondary"
                    @click="onSearch"
                    data-test="search-button"
                    >Search</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div v-if="quote_list.length > 0" class="mt-5" data-test="div-list">
            <v-divider />
            <v-row dense v-for="(quote, index) in quote_list" :key="index">
              <v-col cols="12">
                <v-btn
                  block
                  class="pa-2 mt-1 text-center"
                  @click="onSaveQuoteToProject(quote.id)"
                  :data-test="`quoteButton${index}`"
                >
                  <span class="text-truncate" style="max-width:700px">{{
                    `Quote(${quote.id}) - ${quote.project_name}`
                  }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else class="mt-5" data-test="div-nolist">
            <v-divider />
            <v-card color="grey lighten-2" flat>
              <v-card-text class="body-2 font-weight-bold text-center"
                >No quotes to display</v-card-text
              >
            </v-card>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "SearchQuoteModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    fixtureDesignId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      project_name: null,
      quote_list: [],
    };
  },
  methods: {
    onClose() {
      this.project_name = null;
      this.quote_list.splice(0);
      this.$emit("closeQuoteModal");
    },
    async onSearch() {
      if (!this.project_name) return;
      try {
        const response = await this.$store.dispatch("quotes/searchQuotes", {
          params: { search: this.project_name },
        });
        console.log(response);
        this.quote_list = response;
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    async onSaveQuoteToProject(id) {
      if (!this.fixtureDesignId) return;
      this.$emit("saveQuote", id);
    },
  },
};
</script>
